/* Estilo do botão */
.pulsar {
    display: flex; /* Centralização do conteúdo interno */
    justify-content: center; /* Centraliza o ícone horizontalmente */
    align-items: center; /* Centraliza o ícone verticalmente */
    width: 35px;
    height: 35px;
    background: #e61de3;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0px 12px 12px rgba(26, 26, 26, 0.3);
    z-index: 1000;
    position: relative; /* Permite manipular elementos no fluxo normal */
  }
  
  /* Círculos animados (antes e depois) */
  .pulsar::before,
  .pulsar::after {
    content: '';
    display: block;
    position: absolute;
    left: -10px;
    right: -10px;
    top: -10px;
    bottom: -10px;
    border-radius: 50%;
    border: 1px solid #e61de3;
    animation: animar 1.5s linear infinite;
  }
  
  /* Atraso para o ::after */
  .pulsar::after {
    animation-delay: 0.5s;
  }
  
  /* Keyframes da animação */
  @keyframes animar {
    0% {
      transform: scale(0.5);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: scale(1.5);
      opacity: 0;
    }
  }
  
  /* Texto ou ícone dentro do botão */
  .pulsar span {
    color: white;
    font-size: 18px;
    font-weight: bold;
    z-index: 1;
  }
  
h1 {
    font-family: 'Poppins', sans-serif;
    font-size: 25px;
    text-transform: uppercase;
    background: linear-gradient(to right, #fc72ff, #8f68ff, #487bff, #8f68ff, #fc72ff);
    background-size: 200%; /* Tamanho do gradiente para criar o efeito de movimento */
    background-clip: text;
    -webkit-background-clip: text; /* Necessário para o Safari */
    color: transparent;
    animation: animate-gradient 3s linear infinite; /* Aplica a animação */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  @keyframes animate-gradient {
    0% {
      background-position: 0%;
    }
    100% {
      background-position: 200%;
    }
  }
  
/* Estrutura geral */
.login-container {
    display: flex;
    height: 100vh;
    font-family: Arial, sans-serif;
  }
  
  /* Lado esquerdo com a imagem de fundo */
  .login-image {
    flex: 1;
    /* background-image: url('https://images.unsplash.com/photo-1506748686214-e9df14d4d9d0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDN8fHVuZGVyJTIwdGhlJTIwc3RhcnN8ZW58MHx8fHwxNjc5MzQ3Mzgz&ixlib=rb-1.2.1&q=80&w=1080'); URL temporária da imagem */
    /* background-image: url('https://plus.unsplash.com/premium_photo-1661963212517-830bbb7d76fc?q=80&w=1986&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'); */
    /* background-image: url('https://i.ibb.co/LzJ52kx/5.png'); */
    background-image: url('https://i.ibb.co/KzpKN2D/4.png');
    background-size: cover;
    background-position: center;
  }
  /* Lado direito com o formulário */
  .login-form {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #1e1e2e;
    padding: 40px;
    color: #fff;
  }
  
  .login-form h2 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .login-form p {
    font-size: 1rem;
    margin-bottom: 1.5rem;
    color: #a6a6a6;
  }
  
  .input-group {
    width: 100%;
    margin-bottom: 1rem;
  }
  
  .input-group input {
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    border: none;
    background-color: #292945;
    color: #fff;
    font-size: 1rem;
  }
  
  .input-group input::placeholder {
    color: #a6a6a6;
  }
  
  .login-options {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1.5rem;
    color: #a6a6a6;
  }
  
  .login-options label {
    font-size: 0.9rem;
  }
  
  .forgot-password {
    background: none;
    border: none;
    color: #a6a6a6;
    text-decoration: underline;
    cursor: pointer;
    font-size: 0.9rem;
    padding: 0;
  }
  
  .forgot-password:hover {
    color: #ffffff;
  }
  
  .login-button {
    width: 100%;
    padding: 12px;
    border: none;
    border-radius: 8px;
    background-color: #6a4fe2;
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    margin-top: 1rem;
  }
  
  .login-button:hover {
    background-color: #5c43b8;
  }
  
  .login-divider {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 1.5rem 0;
    color: #a6a6a6;
  }
  
  .login-divider::before,
  .login-divider::after {
    content: "";
    flex: 1;
    height: 1px;
    background-color: #a6a6a6;
  }
  
  .login-divider span {
    margin: 0 10px;
  }
  
  .social-login {
    display: flex;
    gap: 10px;
    margin-bottom: 2rem;
  }
  
  .social-button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    font-weight: bold;
    color: #fff;
    cursor: pointer;
  }
  
  .social-button.google {
    background-color: #db4437;
  }
  
  .social-button.facebook {
    background-color: #4267B2;
  }
  
  .social-button.apple {
    background-color: #333;
  }
  
  footer {
    font-size: 0.8rem;
    color: #a6a6a6;
    text-align: center;
    margin-top: auto;
  }
  